import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from '@mui/material'
import HeaderNotifications from './Notifications'
import ContentCopyIcon from '@mui/icons-material/ContentCopyOutlined'
import { useEffect, useState } from 'react'

function HeaderButtons() {
  const [isSharePanelOpen, setIsSharePanelOpen] = useState<boolean>(false)
  const [copyClicked, setCopyClicked] = useState<boolean>(false)
  const [copyText, setCopyText] = useState<string>('URL kopiëren')

  // const handleShareClick = () => {
  //   setIsSharePanelOpen(true)
  // }

  const handleSharePanelDismiss = () => {
    setIsSharePanelOpen(false)
    setCopyClicked(false)
    setCopyText('URL kopiëren')
  }

  const handleCopyClick = () => {
    navigator.clipboard.writeText('https://ccegpt.nl/user/chat')
    setCopyClicked(true)
  }

  useEffect(() => {
    if (copyClicked) {
      setCopyText('Gekopieerde URL')
    }
  }, [copyClicked])

  return (
    <Box sx={{ mr: 1 }}>
      {/* <HeaderSearch /> */}
      {/* <Button
        variant="contained"
        startIcon={<ShareIcon fontSize="small" />}
        onClick={() => handleShareClick()}
      >
        Delen
      </Button> */}
      <Box sx={{ mx: 0.5 }} component="span">
        <HeaderNotifications />
      </Box>
      <Dialog
        open={isSharePanelOpen}
        onClose={handleSharePanelDismiss}
        sx={{
          '& .MuiDialog-paper': {
            '@media (min-width: 480px)': {
              maxWidth: '600px',
              background: '#FFFFFF',
              boxShadow:
                '0px 14px 28.8px rgba(0, 0, 0, 0.24), 0px 0px 8px rgba(0, 0, 0, 0.2)',
              borderRadius: '8px',
              maxHeight: '200px',
              minHeight: '100px',
            },
          },
        }}
      >
        <DialogTitle>Deel de Bloom Assistant </DialogTitle>
        <DialogContent>
          <Box display="flex" alignItems="center" gap={1}>
            <TextField
              value={'https://ccegpt.nl/user/chat'}
              variant="outlined"
              fullWidth
            />
            <IconButton
              aria-label="Copy"
              onClick={handleCopyClick}
              onKeyDown={(e) =>
                e.key === 'Enter' || e.key === ' ' ? handleCopyClick() : null
              }
            >
              <ContentCopyIcon />
            </IconButton>
            <span>{copyText}</span>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  )
}

export default HeaderButtons
