/**
 * @file global.tsx
 * This file contains global configurations used throughout the project.
 */

/**
 * `global` - an object to store the environment configuration.
 * @type {Object}
 * @property {string} environment - Specifies the working environment.
 *                                  'prod' for production,
 *                                  'dev' for development,
 *                                  'local' for local development.
 */
export const global = {
  environment: 'prod',
  // Storage name in azure storage.
  storage_name: 'ccechatstorage',

  // Container name for specific data within the storage system.
  container_name: 'website',

  // SAS (Shared Access Signature) token for Azure Storage access control.
  // Provides permissions to read, write, and delete within the specified resources.
  // Includes parameters for storage version, service type, resource types,
  sas_token:
    '?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2024-12-19T00:55:42Z&st=2023-12-20T00:55:42Z&spr=https,http&sig=Hh%2BEq%2BIoP4zo05YPT%2BoMHxDwis00HTowkRLjxSLqR4s%3D',
}

/**
 * `base_url_be` - Base URL for backend services.
 * Determines the URL based on the selected environment setting.
 * @type {string}
 */
export const base_url_be =
  global.environment == 'prod'
    ? 'https://cce-be-prod.azurewebsites.net/'
    : global.environment == 'dev'
    ? 'https://cce-be-dev.azurewebsites.net/'
    : 'http://localhost:3333/'

/**
 * `base_url` - Base URL for the frontend services.
 * Sets the URL according to the environment setting.
 * @type {string}
 */
export const base_url =
  global.environment == 'prod'
    ? 'https://ccegpt.nl/'
    : global.environment == 'dev'
    ? 'https://cce-bot-ai-dev.azurewebsites.net/'
    : 'http://localhost:5173/'
