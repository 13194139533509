import { useState, createContext, FC, ReactNode } from 'react'
import { ThemeProvider } from '@mui/material'
import { themeCreator } from './base' // Pastikan ini mengembalikan objek tema yang valid
import { StylesProvider } from '@mui/styles'

type ThemeContextType = (themeName: string) => void

export const ThemeContext = createContext<ThemeContextType>(() => {})

interface ThemeProviderWrapperProps {
  children?: ReactNode // Tipe untuk children
}

const ThemeProviderWrapper: FC<ThemeProviderWrapperProps> = ({ children }) => {
  const curThemeName = localStorage.getItem('appTheme') || 'PureLightTheme'
  const [themeName, setThemeName] = useState(curThemeName) // Menggunakan setThemeName
  const theme = themeCreator(themeName)

  const changeTheme = (newThemeName: string): void => {
    localStorage.setItem('appTheme', newThemeName)
    setThemeName(newThemeName)
  }

  return (
    <StylesProvider injectFirst>
      <ThemeContext.Provider value={changeTheme}>
        <ThemeProvider theme={theme}>{children}</ThemeProvider>
      </ThemeContext.Provider>
    </StylesProvider>
  )
}

export default ThemeProviderWrapper
