import axiosInstance from './AxiosInstance'

export const joinInvitationApi = (values: any) => {
  return axiosInstance
    .post('/join-invitation', values)
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      throw error
    })
}

export const getInvitaionApi = (token: any) => {
  return axiosInstance
    .get(`/invited-users/${token}`)
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      throw error
    })
}

export const inviteApi = (values: any) => {
  return axiosInstance
    .post('/user-invitiation', values)
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      throw error
    })
}

export const registerApi = (values: any) => {
  return axiosInstance
    .post('/register', values)
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      throw error
    })
}

export const loginApi = (values: any) => {
  return axiosInstance
    .post('/login', values)
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      throw error
    })
}

export const requestOTPApi = (values: any) => {
  return axiosInstance
    .post('/request-otp', values)
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      throw error
    })
}

export const validateOTPApi = (values: any) => {
  return axiosInstance
    .post('/verify-otp', values)
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      throw error
    })
}

export const resetPasswordApi = (values: any) => {
  return axiosInstance
    .post('/reset-password', values)
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      throw error
    })
}

export const periodSave = (values: any) => {
  return axiosInstance
    .post('/period', values)
    .then((response) => {
      return response
    })
    .catch((error) => {
      throw error
    })
}

export const verifyEmailApi = (values: any) => {
  return axiosInstance
    .get(`/register/verifaction-mail/${values}`)
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      throw error
    })
}

export const getSettingsApi = () => {
  return axiosInstance
    .get('/settings')
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      throw error
    })
}

export const updateSettingsApi = (values: any) => {
  return axiosInstance
    .post('/settings', values)
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      throw error
    })
}

export const getUsersApi = () => {
  return axiosInstance
    .get('/users')
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      throw error
    })
}
