import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
  useCallback,
} from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, CircularProgress } from '@mui/material'

export type UserType = {
  id?: number
  email?: string
  remember_me_token?: string | null
  first_name?: string
  last_name?: string
  phone?: string
  address?: string | null
  is_verified?: string
  uuid?: string
  roles?: string
  created_at?: string
  updated_at?: string
} | null

// Membuat context dengan tipe default null
type UserContextType = {
  userData: UserType
  updateUser: (newUser: UserType) => void
  updateUserData: (newUser: Partial<UserType>) => void
}

const UserContext = createContext<UserContextType>({
  userData: null,
  updateUser: () => {},
  updateUserData: () => {},
})

type UserProviderProps = {
  children: ReactNode
}

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const navigate = useNavigate()
  // const location = useLocation()
  const [userData, setUserData] = useState<UserType>(null)
  const [isLoading, setIsLoading] = useState(true) // Menambahkan state untuk loading

  useEffect(() => {
    const fetchUserData = async () => {
      const userDataString = localStorage.getItem('user')
      // Mengonversi data dari string ke objek JavaScript
      let userData: any
      if (userDataString !== null) {
        userData = JSON.parse(userDataString)
      }
      if (userData == null) {
        const path = location.pathname
        if (!path.startsWith('/admin') && !path.startsWith('/auth')) {
          navigate('/')
        }
      }
      setUserData(userData)
      setIsLoading(false) // Set loading menjadi false setelah data di-fetch
    }

    fetchUserData()
  }, [navigate])

  const updateUser = useCallback((newUser: UserType) => {
    setUserData(newUser)
  }, [])

  const updateUserData = useCallback((updates: Partial<UserType>) => {
    setUserData((currentUser) => {
      // Jika currentUser adalah null, mungkin Anda ingin menangani secara khusus atau mengembalikan null
      if (currentUser === null) {
        return null
      }

      return {
        ...currentUser,
        ...updates,
      }
    })
  }, [])

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <CircularProgress />
      </Box>
    )
  }

  return (
    <UserContext.Provider value={{ userData, updateUser, updateUserData }}>
      {children}
    </UserContext.Provider>
  )
}

// Hook untuk menggunakan UserContext
export const useUser = () => useContext(UserContext)
