import { FC, ReactNode, useState, createContext } from 'react'

type SidebarContext = {
  sidebarToggle: boolean // Ganti tipe dari unknown menjadi boolean
  toggleSidebar: () => void
  closeSidebar: () => void
}

export const SidebarContext = createContext<SidebarContext>({
  sidebarToggle: false, // Berikan nilai default sesuai tipe
  toggleSidebar: () => {}, // Fungsi kosong sebagai placeholder
  closeSidebar: () => {},
})

interface SidebarProviderProps {
  children: ReactNode
}

export const SidebarProvider: FC<SidebarProviderProps> = ({ children }) => {
  const [sidebarToggle, setSidebarToggle] = useState<boolean>(false) // Tambahkan tipe boolean
  const toggleSidebar = () => setSidebarToggle(!sidebarToggle)
  const closeSidebar = () => setSidebarToggle(false)

  return (
    <SidebarContext.Provider
      value={{ sidebarToggle, toggleSidebar, closeSidebar }}
    >
      {children}
    </SidebarContext.Provider>
  )
}
