import expincLogo from './images/expinc-logo.svg'
import cceLogoWhite from './images/cce-white.svg'
import cceLogo from './images/cce-logo.svg'
import instructie from './images/instructie.png'
import instelling from './images/instelling.png'

const assets = {
  images: {
    expincLogo,
    cceLogoWhite,
    cceLogo,
    instructie,
    instelling,
  },
}

export default assets
