import { ComponentType, ReactNode, Suspense, lazy } from 'react'
import { Navigate } from 'react-router-dom'
import { RouteObject } from 'react-router'

import BaseLayout from '../pages/admins/layouts/BaseLayout'

import SuspenseLoader from '../components/SuspenseLoader'

const Loader =
  <P extends object>(Component: ComponentType<P>) =>
  (props: P): ReactNode =>
    (
      <Suspense fallback={<SuspenseLoader />}>
        <Component {...props} />
      </Suspense>
    )

// Auth
const AuthLogin = Loader(lazy(() => import('../pages/auth/login')))
const AuthRegister = Loader(lazy(() => import('../pages/auth/register')))
const AuthVerifyAccount = Loader(
  lazy(() => import('../pages/auth/verifyAccount'))
)
const AuthOtpCode = Loader(lazy(() => import('../pages/auth/otpCode')))
const AuthForgotPassword = Loader(
  lazy(() => import('../pages/auth/forgotPassword'))
)
const ResetPassword = Loader(lazy(() => import('../pages/auth/resetPassword')))
const Invitation = Loader(lazy(() => import('../pages/auth/invitation')))

// const Status404 = Loader(
//   lazy(() => import('../pages/admins/content/pages/Status/Status404'))
// )

const routesPublic: RouteObject[] = [
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="/auth" replace />,
      },
      {
        path: '/auth',
        children: [
          {
            path: '',
            element: <Navigate to="login" replace />,
          },
          {
            path: 'login',
            element: <AuthLogin />,
          },
          {
            path: 'register',
            element: <AuthRegister />,
          },
          {
            path: 'forgot-password',
            element: <AuthForgotPassword />,
          },
          {
            path: 'verify-account',
            element: <AuthVerifyAccount />,
          },
          {
            path: 'otp-code',
            element: <AuthOtpCode />,
          },
          {
            path: 'reset-password',
            element: <ResetPassword />,
          },
          {
            path: 'invitation/:token',
            element: <Invitation />,
          },
        ],
      },
      {
        path: '*',
        element: <Navigate to="/" replace />,
      },
    ],
  },
]

export default routesPublic
