import { useRoutes } from 'react-router-dom'

import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'

import { CircularProgress, CssBaseline } from '@mui/material'
import ThemeProvider from './pages/admins/theme/ThemeProvider'
import { useEffect, useState } from 'react'
import routesPublic from './routers/router-public'
import routesAdmin from './routers/router-admin'
import routesClient from './routers/router-client'
import { Helmet } from 'react-helmet-async'
import { useUser } from './contexts/UserContext'
import 'dayjs/locale/nl'
import { getSettingsApi } from './api'
// import { IPublicClientApplication } from '@azure/msal-browser'
// import { MsalProvider } from '@azure/msal-react'
// import { CustomNavigationClient } from './constants/NavigationClient'

// type AppProps = {
//   pca: IPublicClientApplication
// }

function App() {
  const { userData } = useUser()
  // const navigate = useNavigate()
  const [role, setRole] = useState('')

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (!userData) {
      setLoading(false)
      // Tidak ada data pengguna, arahkan ke rute publik
      // navigate('/')
    } else {
      // Tentukan peran pengguna berdasarkan data pengguna
      switch (userData.roles) {
        case 'admin':
          setRole('admin')
          break
        case 'client':
          setRole('client')
          break
        default:
          setRole('public')
          break
      }
    }
  }, [userData, loading])

  useEffect(() => {
    // Tandai loading sebagai false setelah userData tersedia
    if (userData) {
      setLoading(false)
    }
  }, [userData])

  useEffect(() => {
    const handleGetSetting = async () => {
      try {
        const response = await getSettingsApi()
        if (response.data.enable_feedbucket) {
          const script = document.createElement('script')
          script.type = 'text/javascript'
          script.noModule = false
          script.defer = true
          script.src = 'https://cdn.feedbucket.app/assets/feedbucket.js'
          script.dataset.feedbucket = response.data.feedbucket_id
          document.head.appendChild(script)
          return () => {
            document.head.removeChild(script)
          }
        }
      } catch (error) {
        console.error('Error fetching settings:', error)
      } finally {
      }
    }
    handleGetSetting()
  }, [role])

  // Tampilkan loading spinner atau indikator selama loading
  if (loading) {
    return <CircularProgress /> // Ganti dengan komponen loading yang sesuai
  }

  const routes = useRoutes(
    role === 'admin'
      ? routesAdmin
      : role === 'client'
      ? routesClient
      : routesPublic
  )

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale="nl">
        <CssBaseline />
        <Helmet>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
          />
        </Helmet>
        {routes}
      </LocalizationProvider>
    </ThemeProvider>
  )
}
export default App
