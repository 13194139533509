/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useRef, useState } from 'react'

// import { NavLink } from 'react-router-dom'

import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Hidden,
  lighten,
  // List,
  // ListItem,
  // ListItemText,
  Popover,
  Typography,
} from '@mui/material'

import { styled } from '@mui/material/styles'
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone'
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone'
// import AccountTreeTwoToneIcon from '@mui/icons-material/AccountTreeTwoTone'
import { useUser } from '../../../../../../contexts/UserContext'
import { useNavigate } from 'react-router'

const UserBoxButton = styled(Button)(
  ({ theme }) => `
        padding-left: ${theme.spacing(1)};
        padding-right: ${theme.spacing(1)};
`
)

const MenuUserBox = styled(Box)(
  ({ theme }) => `
        background: ${theme.colors.alpha.black[5]};
        padding: ${theme.spacing(2)};
`
)

const UserBoxText = styled(Box)(
  ({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`
)

const UserBoxLabel = styled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
`
)

const UserBoxDescription = styled(Typography)(
  ({ theme }) => `
        color: ${lighten(theme.palette.secondary.main, 0.5)}
`
)

function HeaderUserbox() {
  const navigate = useNavigate()
  const { userData, updateUser } = useUser()
  const [openModal, setOpenModal] = useState(false)

  const handleLogout = useCallback(() => {
    // Bersihkan data pengguna dari localStorage
    localStorage.removeItem('user')
    localStorage.removeItem('UID')
    localStorage.removeItem('accessToken')

    // Update context pengguna
    updateUser(null)

    // Arahkan ke halaman login atau halaman lainnya
    window.location.href = '/'
  }, [updateUser, navigate])

  const user = {
    name: userData!.first_name,
    avatar: '/static/images/avatars/1.jpg',
    jobtitle: userData!.roles,
  }

  const ref = useRef<any>(null)
  const [isOpen, setOpen] = useState<boolean>(false)

  const handleOpen = (): void => {
    setOpen(true)
  }

  const handleClose = (): void => {
    setOpen(false)
  }

  return (
    <>
      <UserBoxButton color="secondary" ref={ref} onClick={handleOpen}>
        <Avatar variant="rounded" alt={user.name} src={user.avatar} />
        <Hidden mdDown>
          <UserBoxText>
            <UserBoxLabel variant="body1">{user.name}</UserBoxLabel>
            <UserBoxDescription variant="body2">
              {user.jobtitle}
            </UserBoxDescription>
          </UserBoxText>
        </Hidden>
        <Hidden smDown>
          <ExpandMoreTwoToneIcon sx={{ ml: 1 }} />
        </Hidden>
      </UserBoxButton>
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuUserBox sx={{ minWidth: 210 }} display="flex">
          <Avatar variant="rounded" alt={user.name} src={user.avatar} />
          <UserBoxText>
            <UserBoxLabel variant="body1">{user.name}</UserBoxLabel>
            <UserBoxDescription variant="body2">
              {user.jobtitle}
            </UserBoxDescription>
          </UserBoxText>
        </MenuUserBox>
        <Divider sx={{ mb: 0 }} />
        {/* <List sx={{ p: 1 }} component="nav">
          <ListItem
            button
            to="/dashboards/profile/settings"
            component={NavLink}
          >
            <AccountTreeTwoToneIcon fontSize="small" />
            <ListItemText primary="Account instellingen" />
          </ListItem>
        </List> */}
        <Divider />
        <Box sx={{ m: 1 }}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => setOpenModal(true)}
          >
            <LockOpenTwoToneIcon sx={{ mr: 1 }} />
            Afmelden
          </Button>
        </Box>
      </Popover>
      <Dialog
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Sign Out'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Weet u zeker dat u zich wilt afmelden?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenModal(false)} variant="outlined">
            Annuleren
          </Button>
          <Button onClick={() => handleLogout()} variant="contained" autoFocus>
            Afmelden
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default HeaderUserbox
